/*============ sub page============*/

.page-graphic {
    padding-top:27.3%;
    min-height: 200px;
    height: 40vw;
    max-height: 100vh;
    position:relative;
    display:block;
    // border-bottom: 10px solid var(--green);
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

.splash{
    border-bottom: 10px solid var(--green);
    position: relative;
    .splash_content{
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 45%;
        min-width: 400px;
        background-color: rgba(14, 14, 15, 0.19);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left:12%;
        padding-bottom:10%;
        padding-right:2rem;
        .page-title{
            
            color:var(--white);
            margin-top:1rem;
        }
        .page-summary{
            font-size: clamp(18px, 3vw, 30px);
            font-weight: 700;
            color: var(--white)
        }
    }
}

.page-title{
    font-size: clamp(16px, 4vw, 55px);
    text-transform: uppercase;
    color:var(--green);
    font-weight: 700;
    border-bottom: 10px solid var(--green);
    padding-bottom: .75rem;
    margin-bottom: .5rem;
    display:inline-block;
    margin-top:min(7.5vw, 3rem);
}
.page-body{
    padding-top:min(2vw, 20px);
    font-size: clamp(16px, 2.5vw, 20px);
}

.t-contact{
    .footer-contact-grid{
        span.icon, .social-link-icon{
            height: 26px!important;
            width: 26px!important;
        }
        .tel, a:hover{
            color: var(--green);
        }
        margin-bottom: 4rem;
        font-size: clamp(16px, 3vw, 22px)
    }
    .sub-templates{
        padding-bottom: 6rem;
    }
    .footer-modal{
        display: none;
    }
    form{
        .two-col{
            // grid-gap:0 1rem;
        }
    }
}
.t-career{
    .service-card{
        .card-title{
            margin: 0 1rem;
        }
        .card-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 150px;
            .read-more{
                background-color: var(--green);
                color: var(--white);
                font-weight: 600;
                font-size: clamp(16px, 2.5vw, 20px);
                width: 300px;
                text-align: center;
                padding:0 1rem;
                max-height: 0px;
                overflow: hidden;
                transition: all .3s ease-in-out;
                display: block;
                border:none;
            }
           
        }
        &:hover{

            .read-more{
                overflow: hidden;
                padding:1rem;
                max-height: 100px;
            }
        }
    }
}