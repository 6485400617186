
/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    margin:0;
    text-align: left;
    padding-left: 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    border:2px solid #707070;
    height: clamp(50px, 5vw, 68px);
    font-size: clamp(16px, 3vw, 28px);
    
}
textarea {
    min-height: 223px;
 
}
label.float {
    margin:0;
    line-height: clamp(50px, 5vw, 68px);
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 2rem;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
    font-size: clamp(16px, 3vw, 28px);
    color: var(--black);
}
.js-float-wrap {
    position:relative;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    line-height: 12px;
    font-size: 12px;
    top: 5px;
}
form button.button {
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    width: auto;
   
    font-size: clamp(16px, 3vw, 20px);
    margin-bottom: 1rem;
    padding:1rem 3rem; 
    align-self:flex-start;
    @media screen and (max-width:768px) {
        align-self:stretch;
        padding:1rem;
    }
}