/*============ header ============*/
header.top {
    font-family: var(--font-primary);
    font-size:14px;
    padding:1rem;
    padding-top:2rem;
    top:0;
    z-index: 1000;
    background-color: var(--white);
    // height: 70px;
}
.header-bar-top {
    color: var(--black);
}

header.top ul {
    text-transform: uppercase;
}
header.top li {
    vertical-align:top;
}
.header-menu {
    display: flex;
    justify-content: space-between;
    padding:0 min(2vw, 4rem);
    flex-wrap: wrap;
}
.header-menu > ul {
    text-align:right;
}
.header-menu li {
    margin:20px;
}
.main-menu-wrapper{
    margin-left: auto;
    display:flex;
    align-items: stretch;
    gap:2rem;
    position: relative;
    .row{
        align-items: center;

        .phone-icon{
            margin-right: 1rem;
            margin-bottom: 10px;
        }
        .phone{
            font-size: clamp(16px, 2.5vw ,20px);
            font-weight: 600;
        }
        .site-links{
            margin-left:2rem;
            .site-link{
                margin-bottom: 15px;
            }
        }
    }
}
.main-menu ul {
    text-align:justify;
    line-height:0;
    display:flex;
    align-items: stretch;
    gap:3rem;
}

.main-menu li {
    margin:5px;
    line-height:1;
}
.main-menu li.depth-0 {
    vertical-align:middle;
    margin: 0;

    display:flex;
    flex-direction: column;
    justify-content: center;
    &.active{
        color:var(--green);
      
        font-weight: 700;
        text-shadow: none!important;
        & > a{
            position: relative;
            display:flex;
            justify-content: center;
        }
        & > a::after{
            content:"";
            position: absolute;
            display: block;
            width:90%;
            bottom:-5px;
            border-bottom: 4px solid var(--green);
        }
    }
    &:hover{
        color:var(--green);
        text-shadow: 0 0 1px var(--green);
    }
}
.main-menu{
    font-size: clamp(16px, 2.5vw, 20px);
    font-weight: 600;
    & > ul{
        height: 100%;
    }
    a[href|="/"]{
        display: none!important;
    }
}
.main-menu a {
    padding:15px 0;
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:var(--white);
    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;
    padding:2rem;
    padding-top:3rem;
    justify-content: center;
    &::before{
        content:"";
        border-top:1px solid #4C4D4F;
        position: absolute;
        width: 90%;
        margin:0 auto;
        display: block;
    }
    li.depth-1{
        margin-right: auto;
        text-transform: none;
        .title{
            color: var(--black);
            font-weight: 400;
        }
        &.active,&:hover{
            .title{
                color: var(--green);
            }
        }
    }
}
.main-menu li:hover > ul {
    display:flex;
}


