.subtemplate-about_us{
    background-image: url(../assets/black-bg.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--white);
    padding:8rem 0;
    .contentwrap{
        max-width: 1400px;
    }
    .block-title{
        position: relative;
        font-size: clamp(16px, 4vw, 55px);
        font-weight: 700;
        padding-bottom: 1rem;
        margin-bottom: 4rem;
        max-width: 480px;
        &::after{
            content:"";
            position: absolute;
            width:185px;
            bottom: 0;
            left:0;
            border-bottom: 8px solid var(--green);
        }
    }
    .block-link{
        text-decoration: none;
        background-color: var(--green);
        color: var(--white);
        padding: .75rem 4rem;
        text-transform: uppercase;
        font-weight: 600;
        transition: transform .15s ease-in-out;
        display:inline-block;
        font-size: clamp(14px, 2.5vw, 20px);
        &:hover{
            transform:scale(1.1);
        }
    }
    .block-content{
        line-height: 30px;
        max-width: 540px;
        .text-title{
            line-height: 46px; 
        }
    }
    @media screen and (max-width: 768px) {
        text-align: center;
        .two-col{
            justify-items: center;
        }
        .block-title
        {
            &::after{
                right: 0;
                margin: auto;
            }
        }
    }
}