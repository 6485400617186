/*============ footer ============*/
footer .footer-bottom {

    background:#000000 url(../assets/black-bg-2.svg) center no-repeat;
    background-size: cover;
    color:#fff;

    padding:8rem 0;
    justify-content: stretch;
    .contentwrap-wide{
        width: 100%;
    }
    @media screen and (max-width:992px) {
        padding:4rem 0;
    }
}
.footer-menu-wrapper{
    display:flex;
    width: 100%;
    gap:min(5vw, 8rem);
    align-items: flex-start;
    margin-bottom: 4rem;
 
    a.logo{
        margin-top: 0;
        margin-left: 0;
        img{
            width: 276px;
        }
    }
    @media screen and (max-width:992px) {
        flex-direction: column;
        align-items: center;
        a.logo{
            padding-bottom: 2rem;
        }
    }
}
.footer-menu > ul{
    display:flex;
    gap:2rem 4rem;
    .depth-0 > a > .title{
        font-weight: 600;
        font-size: clamp(16px, 2.5vw, 20px);
    }
    ul{
        margin-top: 1rem;
        display:flex;
        flex-direction: column;  
        font-size: 16px;
        gap:.5rem;
        text-transform: none;
    }
    .title:hover{
        // text-decoration: underline;
        border-bottom: 2px solid var(--white);
    }
    @media screen and (max-width:768px) {
        flex-direction: column;
        text-align: center;
    }
}
.footer-contact{
    @media screen and (max-width:992px) {
        display: flex;
        justify-content: center;
    }
}
.footer-contact-grid{
    display:inline-grid;
    grid-template-columns:auto 1fr;
    grid-gap:1.5rem 1.5rem;
    font-size: 16px;
    .icon, img{
        width:20px;
        height:20px;
        object-fit: contain;
    }
    .socials{
        grid-column:  1 / -1;
        .site-links{
            display: flex;
            flex-direction: column;
            gap:1rem;
        }
        .site-link{
            display: flex;
            margin:0;
            width: auto;
            .social-link-icon{
                margin-right: 1.75rem;
            }
            &:hover{
                filter:none;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
    .tel{
        font-weight: 700;
        &:hover{
            text-decoration: underline;
        }
    }
    .email{
        &:hover{
            font-weight: bold;
            text-decoration: underline;
        }
    }
}
.footer-modal{
    transform: translateY(50%);

  

    margin:0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    .content{
        width: 1050px;
        background-color: var(--green);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding:.75rem 2rem;
        .button-secondary, .button{
            display: inline-flex;
            align-items: center;
            font-size: clamp(16px, 2.5vw, 20px);
            padding:1rem 2rem;
            width: 300px;
            justify-content: center;
        }
        p{
            display: inline-flex;
            align-items: center;
        }
    }
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--white);
    .copyright{
        font-weight: 500;
       
    }
}
.bykeo a {
    color: var(--black);
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}