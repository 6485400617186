/*============ splash ============*/
/*  banner */
.banners-home{
    position: relative;
    .arrow-wrapper{
        margin:0 2rem;

        position:absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        inset:0;
        z-index: 100;
        pointer-events: none;
        @media screen and (max-width:576px) {
            display:none;
        }
        button{
            background-color: transparent; 
            border: 1px solid var(--white);
            height: 42px;
            width: 42px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color .3s ease-in-out, filter .6s ease-in-out;
            cursor: pointer;
            pointer-events: all;
            img{
                transform: translateX(-1px)
            }
            &:hover{
                background-color: var(--white);
                img{
                    transform: translateX(-1px);
                    filter: brightness(0);
                }
            }
        }
        
        .move-next{
            transform: rotate(180deg);
        }

    }
    .banner-stage {
        min-height: calc(100vh - 145px);
        border-bottom: 10px solid var(--green);
        position:relative;
        display: flex; 
        align-items: stretch;
        height: auto;
    }
    .banner {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:block;
        background-position:center;
        background-size:cover;
        text-decoration: none;
        color: var(--white);
        opacity:0;
        pointer-events: none;
        transition: opacity .3s ease-in-out;
        width: 100%;
        z-index: 0;
    
        &.active{
            position: relative;
            pointer-events: all;
            z-index: 10;
            opacity:1;
        }
        .banner-content{
            height: 100%;
            width: 45%;
            min-width: 400px;
            background-color: rgba(14, 14, 15, .19);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
      
            padding: 2rem 5.5rem;
            padding-right: 2rem;
            @media screen and (max-width:576px) {
                width: 100%;
                min-width: 0;
                padding:2rem;
                text-align: center;
            }
            .banner-name{
                display: none;
            }
            .banner-title, .banner-caption{
                max-width: 350px;
                width: 100%; 
            }
            .banner-title{
                font-size: clamp(16px, 4vw, 55px);
                font-weight: 700;
                margin-bottom: 4rem;
                .banner-item-content{
                    width: auto;
                    border-bottom: 10px solid var(--green);
                    
                }
            }
            .banner-caption{
                font-size: clamp(16px, 3vw, 30px);
                font-weight: 700;
                line-height: clamp(20px, 4vw, 55px);
                &::after{
                    margin-top:2rem;
                    text-align: center;
                    display: inline-block;
                    content:"READ MORE";
                    color: var(--white);
                    background-color: var(--green);
                    padding:.25rem 1rem;
                    transition: transform .3s ease-in-out;
                    font-size: clamp(14px, 2.5vw, 20px);
                    font-weight: 600;
                    max-width: 300px;
                    width:100%;
                }
                &:hover{
                    &::after{
                        transform: scale(1.1);
                    }
                } 
            }
        }
    }
    .banner-navigation {
        display:flex;
        justify-content: center;
        position: absolute;
        padding-bottom: 2rem;
        bottom:0;
        left:0;
        right:0;
        z-index: 100;
        .banner-navigation-body{
            display:flex;
            justify-content: center;
            gap:.75rem;
        }
        .banner-nav-item{
           
            height: 20px;
            width: 20px;
            border: 1px solid var(--white);
            border-radius: 50%;
            cursor: pointer;
            &.active{
                background-color: var(--white);
            }
            .banner-name, .banner-title, .banner-caption{
                display: none;
            }
        }
    
    }
    @media screen and (max-width:992px) {
        
    }
} 

