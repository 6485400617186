.card-wrapper{
    display:flex;
    flex-wrap: wrap;
    gap:1rem;
    margin:6rem 2rem;
    justify-content: center;
    .card{
        width: 516px;
        box-shadow: 6px 12px 15px rgba(0, 0, 0, .16);
        text-decoration: none;
        color:var(--black);
        transition: transform .2s linear;
        background-color: var(--white);
        &:hover{
            transform: scale(1.05);
        }
        .card-img{
           
            img{
                height: auto;
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16/9;
                border-bottom: 8px solid var(--green);
            }
        }
        .card-img-default{
            display: block;
            aspect-ratio: 16/9;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 8px solid var(--green);
            img{
                height: auto;
                width: 60%;
                margin:auto;
                object-fit: contain;
                
    
            }
        }
        
        .card-content{
            padding:1rem;
            .card-title{
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                font-size: clamp(16px, 3vw, 30px);
            }
        }
        
    }
}

.two-col{
    display:grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: 768px) {
        grid-template-columns:1fr;
    }
}
.phone-icon{
    content: url('../assets/phone.svg');
    height: 24px;
    width: 24px;
}
.time-icon{
    content: url('../assets/time.svg');

}
.email-icon{
    content: url('../assets/email.svg');
}
.row{
    display:flex;
}
.contentwrap-wide{
    max-width: 1400px;
    position: relative;
    margin:0 auto;
    padding:20px;
}