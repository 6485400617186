.t-service{
    .card-wrapper-two-col{
        display: flex;
        gap: 1fr;
        margin-left:-10px;
        margin-right: -10px;
        flex-wrap: wrap;
      
        .card{
            position: relative;
            width: 50%;
            cursor: pointer;
            text-decoration: none;
            color:var(--black);
            transition: all .2s linear, width .3s ease-in-out;
            background-color: var(--white);
            border:10px solid transparent;
            &:hover{
                transform: scale(1.05);
                border-color: var(--green);
                z-index: 10;
            }
            &.active{
                margin-top: 4rem;
                width: 100%;
                transform: scale(1)!important;
                border-color: transparent;
                .card-img img{
                    aspect-ratio: 16/9!important;
                }
            }
            @media screen and (max-width:768px) {
                width: 100%;
                transform: scale(1)!important;
                &.active .card-img img{
                    aspect-ratio: 4/3!important;
                }
            }
            .card-img{
            
                img{
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 4/3;
                }
            }
            .card-img-default{
                display: block;
                aspect-ratio: 4/3;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 8px solid var(--green);
                img{
                    height: auto;
                    width: 50%;
                    margin:auto;
                    object-fit: contain;
                    
        
                }
            }
            
            .card-content{
                padding:1rem;
                position: absolute;
                bottom:0;
                left:0;
                right:0;
                background-color: rgba(14, 14, 15, .77);
                .card-title{
                    color:var(--white);
                    font-weight: 700;
                    text-align: center;
                    font-size: clamp(16px, 3vw, 30px);
                }
            }
        }
    }
    .service-content{
        display: none;
        width: 100%;
        border:10px solid transparent;
        font-size: clamp(16px, 3vw, 20px);
        margin-bottom: 4rem;
        .text-title{
            font-weight: 600;
            margin-bottom: 0;
        }
        &.active{
            display: flex;
            flex-direction: column;
        }
    }
    #card-content-area{
        padding:10px;
    }

}