
/*============ Contact ============*/
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 48%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}


.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
    gap: 1rem;
    display: flex;
    flex-direction: column;

}


.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}
.result-wrapper{
    font-size: clamp(18px, 4vw, 24px);
    text-align: center;
    padding-top: 3rem;

}