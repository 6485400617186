/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
    transition: filter .3s linear;
    &:hover{
        filter:grayscale(1) brightness(.6);
    }
}
